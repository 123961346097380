import {useRouter} from "next/router";
import filterService from "../../../services/filterService";
import {useDispatch} from "react-redux";

export default function FilterSelectedSlider(props: any) {
    const router = useRouter();
    const options = props.options.split('_');
    const dispatch = useDispatch();

    return (
        <>
            <div role={"button"}
                 onClick={() => filterService.removeFilter([props.options], props.options, 'pr', router, false, dispatch, router.query)}
                 className={"btn-filter border-1 border rounded-5 ps-3 pe-3 pt-2 pb-2"}>
                <span>{options[0]} bis {options[1]}</span>
                <i className="fa-regular fa-circle-xmark ms-2"></i>
            </div>
        </>
    )
}