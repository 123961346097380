import i18next from "i18next";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../stores/store";
import filterService from "../../services/filterService";
import {useRouter} from "next/router";
import React, {useState} from "react";
import {closeDetail, openDetail} from "../../stores/filter/filterSlice";
import FilterOptionSlider from "./option/Slider";
import FilterOptionCheckbox from "./option/Checkbox";
import FilterOffcanvasDetail from "./offcanvas/Detail";
import FilterOptionCategory from "./option/Category";
import {SwipeableDrawer} from "@mui/material";
import single from "../../utils/single";

export default function FilterOffcanvas(props: any) {
    const router = useRouter();
    const [isOpen, setIsOpen]: any = useState(getOffCanvasQuery());

    const dispatch = useDispatch();
    const isDetailOpen = useSelector((state: State) => state.filterState.isDetailOpen);
    const query = useSelector((state: State) => state.filterState.query);
    const [filterDetail, setFilterDetail] = useState<any>(null);
    const [filter, setFilter] = useState<any>(null);
    let filters = props.filters ?? [];
    const excludes = ['leaf_category_id'];
    if (!Array.isArray(filters)) filters = [filters];

    const toggleDrawer = async (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }
            if (isOpen) {
                await filterService.applyFilters(router, dispatch, query, false);
                dispatch(closeDetail());
            }
            setIsOpen(!isOpen);
        };

    function getOffCanvasQuery() {
        try {
            return router.query.offcanvas == 'true';
        }
        catch(e) {
            return false;
        }
    }

    function getFilter(filter: any) {
        setFilter(filter);
        switch (true) {
            case filter.field.includes('discountedPrice'):
                return <FilterOptionSlider start={single(filter.values).minValue}
                                           isOpen={isOpen}
                                           className={"mt-3 mt-md-4 h-100"}
                                           end={single(filter.values).maxValue}
                                           close={close}
                                           filterKey={'pr'}
                                           shallow={true}/>;

            case filter.field === 'brand':
                return <FilterOptionCheckbox options={filter.values} isOpen={isOpen} close={close} filterKey="s" shallow={true}/>;

            case filter.field === 'categories':
                return <FilterOptionCategory options={filter.values} isOpen={isOpen} close={close} shallow={true}/>

            case filter.field.startsWith('property_'):
                const field = filter.field;
                return <FilterOptionCheckbox options={filter.values} isOpen={isOpen} close={close} filterKey={field} shallow={true}/>;
        }
        return <></>
    }

    async function back() {
        await filterService.applyFilters(router, dispatch, query);
        dispatch(closeDetail());
    }

    function showDetail(filter: any) {
        dispatch(openDetail());
        const element = getFilter(filter);
        setFilterDetail(element);
    }

    async function close(push: boolean = false) {
        setIsOpen(false);
        dispatch(closeDetail());
        if (!push) return;
        await filterService.applyFilters(router, dispatch, query, false);
    }

    function getKey(filter: any) {
        switch (true) {
            case filter.field.includes('discountedPrice') || filter.field.includes('products_brand'):
                return filter.field;

            case filter.field === 'categories':
                return 'cf';

            case filter.field.startsWith('property_'):
                const field = filter.field;
                return field.substring(25, field.length);
        }
    }

    return (
        <>
            <button type={"button"} className={"btn btn-alt pt-2 pb-2 ms-lg-3 w-100 text-center"}
                    onClick={toggleDrawer}>
                <span className={"me-2 fw-normal"}>{i18next.t('filter.all').toString()}</span>
                <i className="fa-solid fa-bars"/>
            </button>
            <SwipeableDrawer
                anchor={'right'}
                open={isOpen}
                onClose={toggleDrawer}
                onOpen={toggleDrawer}>
                <div className="position-relative mui-offcanvas h-100">
                    {!isDetailOpen ? (
                        <>
                            <div className="ps-3 pe-3 pt-4 pb-3 mb-2 bg-dark text-white" onClick={() => setIsOpen(false)} role="button">
                                <i className="fa-solid fa-circle-xmark"></i>
                                <span className="ms-2 fw-bold">{i18next.t('general.close').toString()}</span>
                            </div>

                            <div className={"p-3 d-flex flex-row justify-content-between"}>
                                <p className={"fs-5"}>{i18next.t('filter.offcanvasTitle').toString()}</p>
                                <p
                                    className={"mt-1 text-decoration-underline"}
                                    onClick={() => filterService.resetFilter(router, dispatch)}>
                                    {i18next.t('filter.removeFilter').toString()}
                                </p>
                            </div>

                            {filters.length === 0 ? (
                                <p className={"p-3"}>{i18next.t('filter.noFilterAvailable').toString()}</p>
                            ) : <></>}

                            <ul className={"list-unstyled nav-filter"}>
                                {filters.map((filter: any, index: number) => (
                                    <div className={"d-contents"} key={`filter-offcanvas-${index}`}>
                                        {!excludes.includes(filter.field) ?
                                            <li className={"p-3 border-bottom"}
                                                key={`${getKey(filter)}-${index}`}
                                                onClick={() => showDetail(filter)}
                                                role={"button"}>
                                                <span>{filterService.getLabel(filter)}</span>
                                                <i className="fa-solid fa-circle-chevron-right position-absolute end-0 mt-1 me-3"/>
                                            </li>
                                            : <></>}
                                    </div>
                                ))}
                            </ul>

                            <div className={"ps-3 pe-3"}>
                                <button type={"button"} className={"btn btn-black text-white w-100"} onClick={() => close(true)}>
                                    {i18next.t('filter.showSelected').toString()}
                                </button>
                            </div>
                        </>
                    ) : (
                        <div className="filter-detail h-100" key={filter}>
                            <FilterOffcanvasDetail back={() => back()} close={() => close()} filter={filter}>
                                {filterDetail}
                            </FilterOffcanvasDetail>
                        </div>
                    )}
                </div>
            </SwipeableDrawer>
        </>
    )
}
